<template>
  <div>
  <!--  报餐-->
    <div style="margin-left: 15%;margin-top: 60%">
      <el-tag type="primary" effect="dark" style="margin-right: 5%"> 用餐人数 </el-tag>
      <el-input-number v-model="peo_num" :min="1" :max="1000" />
    </div>
    <div style="margin-left: 15%;margin-top: 10%">
      <div v-if="flag">
        <el-button type="danger" style="width: 80%;" @click="sub_yes">用餐人数报多了，请点击!</el-button>
      </div>
      <div v-if="!flag">
        <el-tag type="primary" effect="dark" style="margin-right: 5%"> 减报人数 </el-tag>
        <el-input-number v-model="subtraction_peo_num" :min="1" :max="1000" />
      </div>

    </div>
  <!--  上报-->
    <div v-if="flag" style="width: 100%;position:fixed;bottom: 10%;">
      <el-button type="primary" style="width: 100%;" @click="up_food_num">上报人数</el-button>
    </div>
    <div v-if="!flag" style="width: 100%;position:fixed;bottom: 10%;left: 10%">
      <el-button type="primary" style="width: 40%;" @click="up_food_num">上报人数</el-button>
      <el-button type="danger" style="width: 40%;" @click="subtraction_food_num">减报人数</el-button>
    </div>
  </div>
</template>

<script>
import {setNowHou} from "@/utils/time";
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  name: "up_food_peonum",
  inject: ['reload'],
  data(){
    return{
      peo_num: '1',
      department: '',
      subtraction_peo_num: '1',
      flag: true,
      bdst: ''
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/yz_tel")
    }else {
      this.department = this.$route.params.depart
      this.bdst = this.$route.params.bdst
    }

  },
  methods:{
    up_food_num(){
      if (this.department === ''){
        ElMessage.error("请选择上报部门!")
      }else {
        this.axios.post('/imdFoodPeoNum/UpFoodpeoNum', (response) => {
          if (response.obj){
            this.reload();
            ElMessage({
              message: '上报成功!',
              type: 'success',
            });

          }else{
            ElMessage.error("上报失败，请联系科信部!")
          }
        },{
          time: setNowHou(new Date()),
          num: this.peo_num,
          depart: this.department,
          bdst: this.bdst
        })
      }

    },
    subtraction_food_num(){
      if (this.department === ''){
        ElMessage.error("请选择上报部门!")
      }else {
        this.axios.post('/imdFoodPeoNum/subtractionPeoNum', (response) => {
          if (response.obj){
            this.reload();
            ElMessage({
              message: '减报成功!',
              type: 'success',
            });

          }
        },{
          time: setNowHou(new Date()),
          peo_num: this.subtraction_peo_num,
          depart: this.department,
          bdst: this.bdst
        })
      }
    },
    sub_yes(){
      this.flag = !this.flag;
    }
  }
}
</script>

<style scoped>

</style>
